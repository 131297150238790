import React, { useState, useRef, useEffect } from 'react';
import './Experience.css'; 

const experiences = [
  {
    role: "Board Member & President",
    organization: "Sabancı University Robotics Club (SURK)",
    period: "Jan 2021 - Feb2024",
    duties: [
      "Responsible for coordination, collaboration, and overall management of several project groups in the club.",
      "Acted as robotic workshop Instructor, arranged workshops, introduce students to basics of robotics using Arduino including Syllabus and course presentations preparation.",
      "Attend and run weekly meetings."
    ]
  },
  {
    role: "Board Member",
    organization: "Sabancı University Robotics Club (SURK)",
    period: "Oct 2020 – Dec 2020",
    duties: [
      "Robotic workshop Instructor Assistant (including course presentations preparation based on the given Syllabus).",
      "Participate in weekly meetings."
    ]
  },
  {
    role: "Club Member",
    organization: "Sabancı University Robotics Club (SURK)",
    period: "Oct 2019 - Oct 2020",
    duties: [
      "Attended the workshops and project studies as a regular active member."
    ]
  },
  {
    role: "IOT Engineer",
    organization: "Beebird Technologies",
    period: "June 2023 - Sept 2023",
    duties: [
      "Developed an Ionic plugin for an IOT device and assisted in its integration into a mobile application.",
      "Worked on the testing process of hardware and software of several IOT devices.",
      "Attended prototype presentation and clarification meetings at clients' offices."
    ]
  },
  {
    role: "Instructor",
    organization: "Küçükköy Creative Technologies",
    period: "2 weeks in July 2022",
    duties: [
      "Prepared and delivered workshops for secondary school students on Technology, Engineering, Robotics, and Computer Sciences."
    ]
  },
  {
    role: "Lab Assistant",
    organization: "Mechatronics Lab, Sabancı University",
    period: "Oct 2019 - June 2021",
    duties: [
      "Worked on a vintage FDM 3D printer project in the mechatronics lab."
    ]
  }
];

function ExperienceItem({ experience, activeIndex, index, setActiveIndex }) {
  const isOpen = activeIndex === index;
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else if (contentRef.current) {
      contentRef.current.style.maxHeight = '0px';
    }
  }, [isOpen]);

  const handleClick = () => {
    if (window.innerWidth <= 768) {  // Only for mobile
      if (isOpen) {
        setActiveIndex(null);  // Collapse on click if open
      } else {
        setActiveIndex(index);  // Expand on click if closed
      }
    }
  };

  const handleMouseEnter = () => {
    if (window.innerWidth > 768) {  // Only for wide screens (desktop)
      setActiveIndex(index);  // Expand on hover
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {  // Only for wide screens (desktop)
      setActiveIndex(null);  // Collapse on hover out
    }
  };

  return (
    <div
      className={`experience-item ${isOpen ? 'open' : ''}`}
      onClick={handleClick}  // Expand on click in mobile
      onMouseEnter={handleMouseEnter}  // Expand on hover in wide mode
      onMouseLeave={handleMouseLeave}  // Collapse on hover out in wide mode
    >
      <h3>{experience.role} - {experience.organization}</h3>
      <p className="period">{experience.period}</p>
      <ul className="duties" ref={contentRef}>
        {experience.duties.map((duty, idx) => (
          <li key={idx}>{duty}</li>
        ))}
      </ul>
    </div>
  );
}

function Experience() {
  const [activeIndex, setActiveIndex] = useState(null);  // Track the currently active item

  return (
    <div className="experience-container">
      <h2>Experiences</h2>
      {experiences.map((exp, index) => (
        <ExperienceItem
          key={index}
          experience={exp}
          index={index}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      ))}
    </div>
  );
}

export default Experience;
