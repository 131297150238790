import React from 'react';
import './HomePage.css';
import Experience from '../components/experiences';
import Skills from '../components/skills';
import Hobbies from '../components/hobbies';
import AboutMe from '../components/aboutMe';  

function Home() {
  return (
    <div className='main-container'>
      <h2>Home</h2>
      <div className='AboutMe'>
        <AboutMe />
      </div>
      <div className='Experience'>
        <Experience />
      </div>
      <div className='Skills'>
        <Skills />
      </div>
      <div>
        <Hobbies />
      </div>
    </div>
  );
}

export default Home;
