import React, { useState } from 'react';
import './Skills.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const skillsData = {
  technical: [
    { name: 'C++', level: 5 },
    { name: 'Android Studio', level: 4 },
    { name: 'Arduino', level: 5 },
    { name: 'Java', level: 4 },
    { name: 'Solid Modelling', level: 5 },
    { name: '3D printing', level: 5 },
    { name: 'Python', level: 5 },
    { name: 'GitHub', level: 5 },
    { name: 'React', level: 4 },
    { name: 'Ionic Framework', level: 5 },
    { name: 'TypeScript', level: 5 },
    { name: 'JavaScript', level: 4 },
    { name: 'C#', level: 4 },
    { name: 'Linux (Ubuntu, Kali)', level: 4 },
    { name: 'HTML', level: 3 },
    { name: 'SQL', level: 3 },
    { name: 'MongoDB', level: 3 },
    { name: 'CSS', level: 2 },
    { name: 'Office Programs', level: 3 },
    { name: 'IOT Devices', level: 4 }
  ],
  frameworks: [
    { name: 'Visual Studio', level: 5 },
    { name: 'Spring Framework (Spring Tool Suite)', level: 3 },
    { name: 'React', level: 5 },
    { name: 'Android Studio', level: 5 },
    { name: 'SolidWorks', level: 5 },
    { name: 'Google colab', level: 5 },
    { name: 'VScode', level: 5 },
    { name: 'Ultimaker Cura', level: 5 },
    { name: 'Ionic Framework', level: 5 },
    { name: 'jupyter notebook', level: 5 }
  ],
};

const sortOptions = [
  { value: 'default', label: 'Default' },
  { value: 'descending', label: 'Descending Stars' },
  { value: 'ascending', label: 'Ascending Stars' },
  { value: 'alphabetical', label: 'Alphabetical' }
];

const SkillItem = ({ skill }) => {
  const percentage = (skill.level / 5) * 100;

  return (
    <div className="skill-item">
      <span className="skill-name">{skill.name}</span>
      <div className="skill-circle">
        <CircularProgressbar value={percentage} text={`${skill.level}/5`} />
      </div>
    </div>
  );
};

function Skills() {
  const [sortedTechnicalSkills, setSortedTechnicalSkills] = useState(skillsData.technical);
  const [sortedFrameworks, setSortedFrameworks] = useState(skillsData.frameworks);

  const handleSortChange = (value, section, setSection) => {
    let sortedSkills;
    switch (value) {
      case 'descending':
        sortedSkills = [...section].sort((a, b) => b.level - a.level);
        break;
      case 'ascending':
        sortedSkills = [...section].sort((a, b) => a.level - b.level);
        break;
      case 'alphabetical':
        sortedSkills = [...section].sort((a, b) => a.name.localeCompare(b.name));
        break;
      default:
        sortedSkills = skillsData[section === sortedTechnicalSkills ? 'technical' : 'frameworks'];
    }
    setSection(sortedSkills);
  };

  return (
    <div className="skills-container">
      <h2 className="skills-heading">Technical Skills</h2>
      <div className="skills-sort-select">
        <select onChange={(e) => handleSortChange(e.target.value, sortedTechnicalSkills, setSortedTechnicalSkills)}>
          {sortOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <div className="skills-list">
        {sortedTechnicalSkills.map((skill, index) => (
          <SkillItem key={index} skill={skill} />
        ))}
      </div>

      <h2 className="skills-heading">Frameworks & Tools</h2>
      <div className="skills-sort-select">
        <select onChange={(e) => handleSortChange(e.target.value, sortedFrameworks, setSortedFrameworks)}>
          {sortOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <div className="skills-list">
        {sortedFrameworks.map((skill, index) => (
          <SkillItem key={index} skill={skill} />
        ))}
      </div>
    </div>
  );
}

export default Skills;
