import React, { useEffect } from 'react';
import './Contact.css'; 

function Contact() {
  useEffect(() => {
    // Function to scroll the page to the bottom when the component mounts
    const scrollToBottom = () => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'  
      });
    };

    // Scroll to the bottom when the component is rendered
    scrollToBottom();
  }, []); // Empty dependency array to ensure this runs once on component mount

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1>Get in Touch</h1>
      </div>
      <div className="contact-info">
        {/* GitHub Link */}
        <a href="https://github.com/Egesez" target="_blank" rel="noopener noreferrer" className="contact-link">
          <i className="fab fa-github"></i>
          <span>/Egesez</span>
        </a>
        {/* LinkedIn Link */}
        <a href="https://www.linkedin.com/in/ege-sezginer" target="_blank" rel="noopener noreferrer" className="contact-link">
          <i className="fab fa-linkedin"></i>
          <span>/ege-sezginer</span>
        </a>
        {/* You can add more contact links below */}
      </div>
    </div>
  );
}

export default Contact;
