import React, { useState, useRef, useEffect } from 'react';
import './AboutMe.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // For expand/collapse arrow icons

function AboutMe() {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isExpanded) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`; // Expand to content height
      } else {
        contentRef.current.style.maxHeight = '0px'; // Collapse content
      }
    }
  }, [isExpanded]);

  return (
    <div className={`about-me-item ${isExpanded ? 'open' : ''}`} onClick={handleToggle}>
      <h2 className="about-me-heading">About Me</h2>
      <div className="about-me-header">
        <p className="about-me-name"><strong>Ege Sezginer</strong></p>
        <p className="about-me-profession">Computer Engineer</p>
      </div>
      <div className="about-me-education">
        <p className="education-heading"><strong>Education:</strong></p>
        <ul>
          <li className="education-item">
            <span className="institution">Sabancı University</span>
            <span className="education-period">(Fall 2019 - Spring 2024)</span>
            <span className="degree">Computer Science & Engineering, BSc</span>
          </li>
          <li className="education-item">
            <span className="institution">Istanbul Anadolu High School</span>
            <span className="education-period">(2015 - 2019)</span>
          </li>
        </ul>
      </div>
      <span className='expand-icon'>
        {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
      </span>
      <div className="about-me-expanded" ref={contentRef}>
        <p className="journey-text">
            My passion for engineering ignited with Legos, where I first realized that assembling pieces mirrored the logic of coding just like Lego blocks, lines of code come together to create something functional. Both require creativity and precision to build a working system. This early discovery shaped my approach to problem-solving and laid the foundation for my journey in technology.
        </p>

        <p className="journey-text">
            My academic path began with the CSBridge program, which deepened my interest in computer science. I then gained hands-on experience in the Sabancı University Mechatronics Lab, contributing to projects like restoring a vintage FDM 3D printer. This experience solidified my love for research and development, where I discovered the satisfaction of creating innovative solutions from the ground up.

            As president of the Sabancı University Robotics Club (SURK), I developed valuable leadership skills, learning how to guide teams through complex challenges and foster collaboration.
        </p>
        <p className="journey-text">
            Later, during my IoT internship, I had the opportunity to design and test smart systems, gaining firsthand insight into how technology can simplify and enhance daily life. These experiences fuel my ongoing passion for pushing the boundaries of innovation and developing solutions that make a real-world impact.
        </p>
        
        
      </div>
    </div>
  );
}

export default AboutMe;
