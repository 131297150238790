import React from 'react';
import './Projects.css'; // Import the CSS file
import sensorModule from '../projImages/SensorModule.jpg';
import servoModule from '../projImages/ServoModule.jpg';
import sandy from '../projImages/Sandy.jpg';
import suavLogo from '../projImages/SuavLogo.png';

function Projects() {

  const projects = [
    {
      title: 'Control UI for Raman Spectrum Device',
      description: 'Developed a control UI for a Raman Spectrum device using the Electron framework and React. Mainly contributed to the “Device communication” part of the project.',
      images: [] // Empty array for no images
    },
    {
      title: 'Sahaya.com',
      description: 'Developed a website using React where each group member contributed to the full-stack development of the website. Scrum management was applied for collaboration. (Further details: <a href="https://github.com/SU-CS308-22FA/Sahaya-Team24" target="_blank" rel="noopener noreferrer">GitHub Repository</a>)',
      images: [] // Empty array for no images
    },
    {
      title: 'IoT Smart Combi',
      description: 'Efficiently control the central heating system (combi) within a household. This is achieved by interpreting data from temperature sensors located in various rooms, allowing users to adjust the temperature to their preferences. Additionally, the project aims to predict the time it takes for the heating system to reach the user-set temperature.',
      images: [sensorModule, servoModule] // Array of image paths
    },
    {
      title: 'Android application',
      description: 'Designed a native android application in Android Studio which enables users to view the news collected from a provided server using REST API and allows users to make comments on each news.',
      images: [] // Empty array for no images
    },
    {
      title: 'Java Rest API',
      description: 'Developed a REST API for banking transactions using the Spring framework (Java) with MongoDB connection.',
      images: [] // Empty array for no images
    },
    {
      title: 'Sandy',
      description: 'Worked on the renewal process of a vintage Stratasys Dimension Elite 3D printer in the Sabancı University Mechatronics Lab. The project involved compiling Marlin firmware and designing a custom direct-drive printhead to update and enhance the printer\'s performance. This project aimed to modernize the machine, making it compatible with contemporary technologies and significantly improving its usability.',
      images: [sandy] // Empty array for no images
    },
    {
      title: 'SUAV Community',
      description: 'Led the formation of SUAV, a UAV-focused community at Sabancı University aiming to participate in TEKNOFEST\'s "DogFight competition." As the Robotics Club president, I helped gather the team, served as a board member for a year, and contributed to structuring the community and its management processes, including conducting interviews for new members.',
      images: [suavLogo] // Empty array for no images
    }
  ];

  return (
    <div className="projects-container">
      <h2 className="projects-title">Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <h3>{project.title}</h3>
            <div className="project-images-container">
              {project.images.map((image, index) => (
                <div key={index} className="project-image">
                  <img src={image} alt={`Project ${index + 1}`} />
                </div>
              ))}
            </div>
            {/* Use dangerouslySetInnerHTML to render the HTML content in description */}
            <p dangerouslySetInnerHTML={{ __html: project.description }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
