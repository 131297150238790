import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AppBar.css'; // You can define your styles in this CSS file

function AppBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu if the user clicks outside of it
  const handleOutsideClick = (event) => {
    if (isOpen && !event.target.closest('.app-bar')) {
      setIsOpen(false);
    }
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  // Add event listener for clicks outside the menu
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="app-bar">
      <div className="logo">
        <Link to="/" onClick={closeMenu}>Ege Sezginer</Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        ☰ {/* Hamburger icon */}
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" onClick={closeMenu}>Home</Link>
        </li>
        <li>
          <Link to="/projects" onClick={closeMenu}>Projects</Link>
        </li>
        <li>
          <Link to="/contact" onClick={closeMenu}>Contact</Link>
        </li>
      </ul>
      {/* Backdrop overlay */}
      {isOpen && <div className="backdrop" onClick={toggleMenu}></div>}
    </div>
  );
}

export default AppBar;
