import React from 'react';
import './Hobbies.css'; // Make sure to create this CSS file

const hobbies = [
  { 
    name: '3D Printing', 
    description: <>Modifying my Ender3v2. Follow me on <a href="https://www.thingiverse.com/egesez/designs" target="_blank" rel="noopener noreferrer">Thingiverse</a>.</>
  },
  { name: 'Guitar', description: 'Strumming melodies and learning new chords.' },
  { name: 'Basketball', description: 'Played in a semi-professional league for Dinamo SK.' },
  { name: 'Windsurfing', description: 'Riding the windsurf every summer in Bitez.' },
  { name: 'Robotics & IoT Devices', description: 'Building smart devices and robots.' }
];

const HobbyCard = ({ hobby }) => (
  <div className="hobby-card">
    <div className="hobby-card-inner">
      <div className="hobby-card-front">
        <h3 className='resetMP'>{hobby.name}</h3>
      </div>
      <div className="hobby-card-back">
        <p className='resetMP'>{hobby.description}</p>
      </div>
    </div>
  </div>
);

function Hobbies() {
  return (
    <div className="hobbies-container">
      {hobbies.map((hobby, index) => (
        <HobbyCard key={index} hobby={hobby} />
      ))}
    </div>
  );
}

export default Hobbies;
